import 'bootstrap/dist/css/bootstrap.css';
import "./assets/fonts/roboto/index.css";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import TagManager from 'react-gtm-module';

import { icons } from './assets/icons';

import { Provider } from 'react-redux';
import store from './store';
import {QueryClientProvider} from "react-query";
import {queryClient} from "./services/queryClient";
import {CookiesProvider} from "react-cookie";

React.icons = icons

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_TAGMANAGER
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root')
);
